import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../app.component";

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.sass']
})
export class LogoutComponent implements OnInit
{
  /**
   * Reference to the main application component
   */
  app: AppComponent;


  /**
   * Default constructor
   */
  constructor()
  {
    this.app = AppComponent.singleton;
  }


  /**
   * Start logout
   */
  ngOnInit(): void
  {
    this.app.api.logout(this.receiveLogout.bind(this));
  }


  /**
   * Handle a logout response
   */
  receiveLogout(res: any, statusText: string, status: string): void
  {
    /* finish removing login data in the app */
    this.app.user = undefined;
    this.app.isLoggedIn = false;
    this.app.updateMenu();
    this.app.api.removeToken();
    this.app.goHome();
  }
}
