<!-- AT icon -->
<div class="at-icon">
  <div class="at-icon-flag">
    <img src="/assets/flag.png" width="100%" alt="Flag">
  </div>
  <div class="at-icon-text">{{app.text['app']['translated']}}</div>
</div>

<!-- Brief site introduction in fancy handwriting font -->
<div class="at-read-intro" *ngIf="app.user === undefined">
  {{app.text['read']['brief-intro']}}
</div>

<!-- Site under development message -- no documents for this language -->
<div class="at-read-nav-container at-read-under-construction" *ngIf="app.documentIndex.length === 0">
  This site is under development and there are currently no documents available for this language.  Please check back again soon.
</div>

<!-- Navigation history -->
<div class="at-read-nav-container" *ngIf="documentHistory.length > 0">
  <span (click)="rewindHistory(-1)" class="at-read-nav-link">
    {{app.text['read']['home']}}
  </span>
  <span class="at-read-nav-link" *ngFor="let item of documentHistory; let i = index" (click)="rewindHistory(i)">
    <mat-icon class="at-shift-down-plus">chevron_left</mat-icon>
    {{item.title}}
  </span>
</div>

<!-- Section/Document buttons -->
<div class="at-read-sections" *ngIf="!documentSelected">
  <button mat-stroked-button class="at-read-mainitem" *ngFor="let node of documentBranch" (click)="expandNode(node)">
    {{node.title}}
  </button>
</div>

<!-- Document text -->
<div class="at-read-document" *ngIf="documentSelected">
  <div style="display:grid" *ngIf="inProgress"><mat-icon class="spin at-center at-shift-down">autorenew</mat-icon></div>
  <div *ngIf="document !== undefined">
    <div class="at-read-document-title">{{document.title}}</div>
    <div class="at-read-document-paragraph" *ngFor="let paragraph of document.body">
      <span class="at-read-document-sentence" *ngFor="let sentence of paragraph">
        {{sentence}}
      </span>
    </div>
  </div>
</div>

<!-- comment section -->
<div class="at-read-mainitem" style="display:grid; border:0;" *ngIf="documentSelected">
  <!-- IF NOT LOGGED IN: Notice to login or signup to comment -->
  <div *ngIf="app.user === undefined">
    <a (click)="app.goSignup()">{{app.text['read']['login-comment'][0]}}</a>
    {{app.text['read']['login-comment'][1]}}
    <a (click)="app.goLogin()">{{app.text['read']['login-comment'][2]}}</a>
    {{app.text['read']['login-comment'][3]}}
  </div>
  <!-- IF LOGGED IN: Text area and button to submit comment -->
  <div *ngIf="app.user !== undefined" class="at-read-comment-input">
    <mat-form-field class="at-read-comment-input-text-area">
      <mat-label>{{app.text['read']['public-comment']}} {{app.user.fullname}}</mat-label>
      <textarea matInput [(ngModel)]="userComment" (keypress)="addCommentOnEnter($event)"></textarea>
    </mat-form-field>
    <button mat-stroked-button (click)="addComment()" [disabled]="userComment.length === 0 || isAddingComment">
      <span *ngIf="!isAddingComment">{{app.text['read']['post-button']}}</span>
      <span *ngIf="isAddingComment"><mat-icon class="spin">autorenew</mat-icon></span>
    </button>
  </div>
</div>

<!-- Button to show comments and also comments -->
<div class="at-read-mainitem" *ngIf="documentSelected" style="display:grid; border:0;">
  <button mat-stroked-button (click)="showComments()" *ngIf="!isShowingComments">
    {{app.text['read']['show-comments-button']}}
  </button>
  <div class="at-center" *ngIf="isLoadingComments"><mat-icon class="spin at-shift-down">autorenew</mat-icon></div>
  <div *ngIf="!isLoadingComments && isShowingComments">
    <div *ngIf="comments.length === 0">{{app.text['read']['no-comments-yet']}}</div>
    <div *ngFor="let comment of comments">
      <div class="at-read-comment">
        {{comment.comment_text}}
        <div>
          <span class="at-read-comment-username">
            {{comment.up}}&nbsp;&nbsp;
            <mat-icon class="at-mat-icon-small" (click)="voteUp(comment)">thumb_up</mat-icon>
            <mat-icon class="at-mat-icon-small" (click)="voteDown(comment)">thumb_down</mat-icon>
            {{comment.dn}}
          </span>
          <span class="at-read-comment-username at-subtle at-float-right at-shift-down">
            {{getCommentDateTime(comment.dt)}}
            {{app.text['read']['by']}} {{comment.screen_name}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Button to close the document -->
<div class="at-read-mainitem" *ngIf="documentSelected" style="display:grid; border:0;">
  <button mat-stroked-button (click)="rewindHistory()">
    {{app.text['read']['close-document-button']}}
  </button>
</div>
