import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {SignupComponent} from "./signup/signup.component";
import {ReadComponent} from "./read/read.component";
import {ActivateComponent} from "./activate/activate.component";
import {PasswordResetComponent} from "./password-reset/password-reset.component";
import {LogoutComponent} from "./logout/logout.component";
import {SettingsComponent} from "./settings/settings.component";

const routes: Routes = [
  {path: '', component: ReadComponent},
  {path: 'doc', component: ReadComponent, children: [{path: '**', component: ReadComponent}]},
  {path: 'signup', component: SignupComponent},
  {path: 'activate', component: ActivateComponent},
  {path: 'login', component: LoginComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'pwreset', component: PasswordResetComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule
{
}
