import {Component, OnInit} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {AmericaTranslated} from '../../../api/src/at';
import AmericaTranslatedApiImpl = AmericaTranslated.AmericaTranslatedApiImpl;
import DocumentNode = AmericaTranslated.DocumentNode;
import {HttpClient} from "@angular/common/http";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TosComponent} from "./tos/tos.component";
import {UnderConstructionComponent} from "./under-construction/under-construction.component";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit
{
  /**
   * A singleton for all other components to access
   */
  static singleton: AppComponent;


  /**
   * An API object for all the world to share
   */
  api: AmericaTranslated.AmericaTranslatedApi;


  /**
   * A flag to tell us if we are using the development API
   */
  apiIsDev: boolean = false;


  /**
   * A set of all of the language packs
   */
  languagePacks: any = undefined;


  /**
   * The language pack for the selected language
   */
  text: any = undefined;


  /**
   * User object
   */
  user: AmericaTranslated.User|undefined = undefined;


  /**
   * User settings object
   */
  userSettings: AmericaTranslated.UserSettings = {
    'lang': window.navigator.language.split('-')[0]
  };


  /**
   * A flag to indicate if user is logged in
   */
  isLoggedIn: boolean = false;


  /**
   * Available language options
   */
  languageOptions: LanguageOption[] = [
    {display: 'English', abbreviation: 'en'},
    {display: 'Español', abbreviation: 'es'},
    {display: '中文', abbreviation: 'zh'}
  ];


  /**
   * An aggregation of all errors that have occurred in this session
   */
  errorAggregation: Array<string> = new Array<string>();


  /**
   * Search query value
   */
  searchQuery: string = '';


  /**
   * A tree of document references
   */
  documentIndex: Array<DocumentNode> = new Array<DocumentNode>();


  /**
   * A list of listener functions to invoke when the document index changes
   */
  documentChangeListeners: Array<Function> = new Array<Function>();


  /**
   * A list of listener function to invoke when the language selection changes
   */
  languageChangeListeners: Array<Function> = new Array<Function>();


  /**
   * Settings menu item
   */
  settingsMenuItem: MenuItem = {
    icon: 'settings',
    text: 'Settings',
    action: this.goSettings.bind(this)
  };


  /**
   * Logout menu item
   */
  logoutMenuItem: MenuItem = {
    icon: 'exit_to_app',
    text: 'Logout',
    action: this.goLogout.bind(this)
  };


  /**
   * Login menu item
   */
  loginMenuItem: MenuItem = {
    icon: 'person',
    text: 'Login',
    action: this.goLogin.bind(this)
  };


  /**
   * Sign up menu item
   */
  signUpMenuItem: MenuItem = {
    icon: 'library_add',
    text: 'Sign Up',
    action: this.goSignup.bind(this)
  };


  /**
   * Menu configuration
   */
  menuItems: MenuItem[] = [
      this.loginMenuItem,
      this.signUpMenuItem
  ];


  /**
   * Create a new AppComponent
   *
   * @param http
   * @param router
   * @param snackBar
   */
  constructor(public http: HttpClient, public router: Router, private snackBar: MatSnackBar, public dialog: MatDialog)
  {
    AppComponent.singleton = this;
    this.api = new AmericaTranslatedApiImpl();
    this.apiIsDev = this.api.isDev();
    this.refreshUser();
    this.loadDocumentIndex();
    this.http.get('/assets/text.json').subscribe(this.receiveLanguagePacks.bind(this));
  }


  /**
   * Run after view is initialized
   */
  ngOnInit()
  {
  }


  /**
   * Show a dialog with the "under construction" banner
   */
  openUnderConstruction(): void
  {
    const config = new MatDialogConfig();
    config.maxHeight = '80vh';

    const dialogRef = this.dialog.open(UnderConstructionComponent, config);
  }


  /**
   * Close the dialog with the "under construction" banner
   */
  closeUnderConstruction(): void
  {
    this.dialog.closeAll();
  }


  /**
   * Handle the response for the language packs
   * @param data Response data
   */
  public receiveLanguagePacks(data: any): void
  {
    this.languagePacks = data['text'];
    this.text = this.languagePacks[this.userSettings.lang] || this.languagePacks['en'];
    this.loadDocumentIndex();
  }


  /**
   * Set the selected language
   *
   * @param language
   */
  public setLanguage(language?: string): void
  {
    /* use the bound variable if no parameter is provided */
    if (language === undefined)
      language = this.userSettings.lang;
    else
      this.userSettings.lang = language;

    /* set the selected option if it is available */
    if (this.languagePacks[language] !== undefined)
      this.text = this.languagePacks[language];

    /* reload the document index */
    this.loadDocumentIndex();

    /* call all language change listeners */
    this.fireLanguageChangeListeners();
  }


  /**
   * Add a language change event listener to the list
   */
  addLanguageChangeEventListener(listener: Function): void
  {
    this.languageChangeListeners[this.languageChangeListeners.length] = listener;
  }


  /**
   * Call all of the language change listeners
   * @private
   */
  private fireLanguageChangeListeners()
  {
    for (let listener of this.languageChangeListeners)
      listener(this.userSettings.lang);
  }


  /**
   * Load the index of available documents
   */
  public loadDocumentIndex(): void
  {
    this.api.getDocument(this.receiveDocumentIndex.bind(this), this.userSettings.lang, 'index');
  }


  /**
   * Handle the response requesting the document index
   * @param res
   * @param statusText
   * @param status
   */
  public receiveDocumentIndex(res: any, statusText: string, status: string): void
  {
    if (res.ok)
      this.documentIndex = res.data.doc.documents;
    else
      this.documentIndex = [];

    this.fireDocumentsChangedEvent();
  }


  /**
   * Add a document change event listener to the list
   */
  addDocumentChangeEventListener(listener: Function): void
  {
    this.documentChangeListeners[this.documentChangeListeners.length] = listener;
  }


  /**
   * Fire an event indication the the document index has changed
   * @private
   */
  private fireDocumentsChangedEvent(): void
  {
    for (let listener of this.documentChangeListeners)
      listener(this.documentIndex);
  }


  /**
   * Route to the Home/Reader component
   */
  public goHome()
  {
    this.router.navigateByUrl('/');
  }


  /**
   * Route to the Login component
   */
  public goLogin(): void
  {
    this.router.navigateByUrl('/login');
  }


  /**
   * Route to the Logout component
   */
  public goLogout(): void
  {
    this.router.navigateByUrl('/logout');
  }


  /**
   * Route to the user settings component
   */
  public goSettings(): void
  {
    this.router.navigateByUrl('/settings');
  }


  /**
   * Route to the Signup component
   */
  public goSignup(): void
  {
    this.router.navigateByUrl('/signup');
  }


  /**
   * Show the set of errors passed in here and add them to the aggregate error list
   *
   * @param errors {Array<string>} The new list of errors to display
   */
  showErrors(errors: Array<string>): void
  {
    /* add to the aggregate error list */
    this.errorAggregation = this.errorAggregation.concat(errors);

    /* create configuration for the snackbar */
    const config = new MatSnackBarConfig();
    config.duration = 0;
    config.panelClass = ['at-snackbar-error'];

    this.snackBar.open(
      errors.join(' \n '),
      'Close',
      config
    );
  }


  /**
   * Show the set of errors passed in here and add them to the aggregate error list
   *
   * @param status The new list of errors to display
   */
  showSuccess(status: string): void
  {
    /* create configuration for the snackbar */
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    config.panelClass = ['at-snackbar-success'];

    this.snackBar.open(
      status,
      'Close',
      config
    );
  }


  /**
   * Call the API to get user data
   */
  refreshUser(): void
  {
    this.api.readUser(this.receiveUserRefreshResponse.bind(this));
    this.api.readSettings(this.receiveSettingsRefreshResponse.bind(this));
  }


  /**
   * Handle a response from the API
   *
   * @param res The response object
   * @param statusText Status text
   * @param status Status value
   */
  receiveUserRefreshResponse(res: any, statusText: string, status: string): void
  {
    if (res.ok)
      this.user = res.data.user;
    this.isLoggedIn = this.user !== undefined;
    this.updateMenu();
  }


  /**
   * Handle a response from the API
   *
   * @param res The response object
   * @param statusText Status text
   * @param status Status value
   */
  receiveSettingsRefreshResponse(res: any, statusText: string, status: string): void
  {
    if (res.ok)
      this.userSettings = res.data.settings;
    this.setLanguage();
  }


  /**
   * Update the action menu
   */
  updateMenu(): void
  {
    if (this.isLoggedIn)
      this.menuItems = [this.settingsMenuItem, this.logoutMenuItem];
    else
      this.menuItems = [this.loginMenuItem, this.signUpMenuItem];
  }
}


/**
 * TODO: Move this somewhere else
 * Menu item
 */
export interface MenuItem
{
  icon: string;
  text: string;
  action?: Function;
  subMenu?: string;
}


/**
 * TODO: Move this somewhere else
 * Language option
 */
export interface LanguageOption
{
  display: string;
  abbreviation: string;
}
