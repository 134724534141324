<div class="at-top-home-nav" (click)="app.goHome()">
  <span><mat-icon>chevron_left</mat-icon></span>
  <span style="position:relative; top:-7px;">{{app.text['app']['return-link']}}</span>
</div>

<div class="at-icon">
  <div class="at-icon-flag">
    <img src="/assets/flag.png" width="100%" alt="Flag">
  </div>
  <div class="at-icon-text">{{app.text['app']['translated']}}</div>
</div>

<div *ngIf="!forgotPassword">
  <div class="at-login-title">
    {{app.text['login']['login']}}
  </div>

  <div class="at-login-field">
    <mat-form-field class="at-login-field-text">
      <mat-label>{{app.text['login']['email-address']}}</mat-label>
      <input matInput type="email" [(ngModel)]="email">
    </mat-form-field>
  </div>

  <div class="at-login-field">
    <mat-form-field class="at-login-field-text">
      <mat-label>{{app.text['login']['password']}}</mat-label>
      <input matInput type="password" [(ngModel)]="password" (keypress)="doLoginOnEnter($event)">
    </mat-form-field>
  </div>

  <div class="at-login-field">
    <button class="at-login-field-text" mat-raised-button color="primary" [disabled]="inProgress" (click)="doLogin()">
      {{app.text['login']['login']}}
      &nbsp;&nbsp;
      <mat-icon *ngIf="inProgress" class="spin">autorenew</mat-icon>
    </button>
  </div>

  <div class="at-login-field">
    <div class="at-login-field-text at-login-accent" (click)="forgotPassword=true">
      {{app.text['login']['forgot-password']}}
    </div>
  </div>
</div>

<div *ngIf="forgotPassword">
  <div *ngIf="!resetOk">
    <div class="at-login-title">
      {{app.text['login']['reset-password']}}
    </div>

    <div class="at-login-field">
      <mat-form-field class="at-login-field-text">
        <mat-label>{{app.text['login']['email-address']}}</mat-label>
        <input matInput type="email" [(ngModel)]="email">
      </mat-form-field>
    </div>

    <div class="at-login-field">
      <button class="at-login-field-text" mat-raised-button color="primary" [disabled]="inProgress" (click)="doResetPassword()">
        {{app.text['login']['send-password-reset-link']}}
        &nbsp;&nbsp;
        <mat-icon *ngIf="inProgress" class="spin">autorenew</mat-icon>
      </button>
    </div>

    <div class="at-login-field">
      <div class="at-login-field-text at-login-accent" (click)="forgotPassword=false">
        {{app.text['login']['back-to-login']}}
      </div>
    </div>
  </div>

  <div *ngIf="resetOk">
    <div class="at-login-success">
      {{app.text['login']['check-your-email']}}
    </div>
  </div>
</div>
