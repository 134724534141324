import {Component, OnInit, TemplateRef} from '@angular/core';
import {AppComponent} from "../app.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TosComponent} from "../tos/tos.component";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass']
})
export class SignupComponent implements OnInit
{
  /**
   * Reference to our main app singleton
   */
  app: AppComponent;


  /**
   * Request message to send to the API
   */
  request = {
    email: '',
    name: '',
    agree: false
  };


  /**
   * Flat to let us know if the sign up is in progress
   */
  inProgress: boolean = false;


  /**
   * Flag to let us know if the sign up is ok
   */
  signUpOk: boolean = false;


  /**
   * Default constructor
   * @param dialog
   */
  constructor(public dialog: MatDialog)
  {
    this.app = AppComponent.singleton;
  }


  ngOnInit(): void
  {
  }


  /**
   * Show a dialog with the terms of service
   */
  openTos(): void
  {
    const config = new MatDialogConfig();
    config.maxHeight = '80vh';

    const dialogRef = this.dialog.open(TosComponent, config);
  }


  /**
   * Validate the input fields and send to API
   */
  doSignup(): void
  {
    this.inProgress = true;

    const user = {
      'email': this.request.email,
      'fullname': this.request.name
    };
    const settings = {
      'lang': this.app.userSettings.lang
    };
    this.app.api.signUp(this.handleSignUpResponse.bind(this), user, settings);
  }


  /**
   * Handle a sign-up response from the API
   *
   * @param data Response data
   * @param statusText Status text
   * @param status Status message
   * @private
   */
  private handleSignUpResponse(data: any, statusText: string, status: any): void
  {
    this.signUpOk = data.ok;
    if (!data.ok)
      this.app.showErrors(data.errors);

    this.inProgress = false;
  }
}
