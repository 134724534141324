<div class="at-top-home-nav" (click)="app.goHome()">
	<span><mat-icon>chevron_left</mat-icon></span>
	<span style="position:relative; top:-7px;">{{app.text['app']['return-link']}}</span>
</div>

<div class="at-icon">
	<div class="at-icon-flag">
		<img src="/assets/flag.png" width="100%" alt="Flag">
	</div>
	<div class="at-icon-text">{{app.text['app']['translated']}}</div>
</div>

<div class="at-settings-section">
	<mat-form-field class="at-settings-section-select">
		<mat-label>{{app.text['settings']['language']}}</mat-label>
		<mat-select [(ngModel)]="app.userSettings.lang">
			<mat-option *ngFor="let option of app.languageOptions" [value]="option.abbreviation">
				{{option.display}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>

<div class="at-settings-section">
	<button mat-raised-button color="primary" style="width:100%;" (click)="doSaveSettings()">
    {{app.text['settings']['save-settings']}}
  </button>
</div>
