import {Component, OnInit} from '@angular/core';
import {ParamMap} from "@angular/router";
import {AppComponent} from "../app.component";

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.sass']
})
export class ActivateComponent implements OnInit
{
  /**
   * Reference to the main app component
   */
  app: AppComponent;


  /**
   * Email to activate
   */
  email: string = '';


  /**
   * Activation key
   */
  key: string = '';


  /**
   * User will enter new password here
   */
  password1: string = '';


  /**
   * User will repeat new password here
   */
  password2: string = '';


  /**
   * Controls enabling the submit button
   */
  passwordOk: boolean = false;


  /**
   * Flag to indicate if activation was successful
   */
  activateOk: boolean = false;


  /**
   * Flag to indicate if activation is in progress
   */
  inProgress: boolean = false;


  /**
   * Flag to indicate if password complexity hint should be visible
   */
  showComplexityHint: boolean = true;


  /**
   * Text to stick into the HTML if passwords do not match
   */
  passwordMatches: string = '';


  /**
   * Is the password long enough?
   */
  isLongEnough: boolean = false;


  /**
   * Does the password have upper case?
   */
  hasUpper: boolean = false;


  /**
   * Does the password have lower case?
   */
  hasLower: boolean = false;


  /**
   * Does the password have a number?
   */
  hasNumeric: boolean = false;


  /**
   * Default constructor
   */
  constructor()
  {
    this.app = AppComponent.singleton;
  }


  /**
   * Get the query string parameters
   */
  ngOnInit(): void
  {
    const paramMap: ParamMap = this.app.router.parseUrl(this.app.router.url).queryParamMap;
    this.email = paramMap.get('email') || '';
    this.key = paramMap.get('key') || '';
  }


  /**
   * Validate the password
   */
  validatePassword(delay: number = 1): void
  {
    if (delay === 1)
    {
      setTimeout(this.validatePassword.bind(this), 150, 0);
      return;
    }

    this.isLongEnough = this.password1.length >= 8

    this.hasUpper = false;
    for (let i = 0; i < this.password1.length; i++)
      if ('A' <= this.password1[i] && this.password1[i] <= 'Z')
      {
        this.hasUpper = true;
        break;
      }

    this.hasLower = false;
    for (let i = 0; i < this.password1.length; i++)
      if ('a' <= this.password1[i] && this.password1[i] <= 'z')
      {
        this.hasLower = true;
        break;
      }

    this.hasNumeric = false;
    for (let i = 0; i < this.password1.length; i++)
      if ('0' <= this.password1[i] && this.password1[i] <= '9')
      {
        this.hasNumeric = true;
        break;
      }

    if (this.password1 === this.password2)
      this.passwordMatches = '';
    else
      this.passwordMatches = '(' + this.app.text['activate']['passwords-no-match'] + ')';

    this.showComplexityHint = !(this.hasNumeric && this.hasLower && this.hasUpper && this.isLongEnough);

    this.passwordOk = this.passwordMatches === '' && !this.showComplexityHint;
  }


  /**
   * Activate the user account
   */
  doActivate(): void
  {
    this.inProgress = true;

    this.app.api.verifyUser(
        this.receiveActivateResponse.bind(this),
        this.email,
        this.key,
        this.password1,
        this.password2
    );
  }


  /**
   * Handle a response from the API
   *
   * @param res The response object
   * @param statusText Status text
   * @param status Status value
   */
  receiveActivateResponse(res: any, statusText: string, status: string): void
  {
    this.inProgress = false;
    if (res.ok)
    {
      this.app.user = res.data.user;
      this.app.goHome();
    }
    else
    {
      this.app.showErrors(res.errors);
    }
  }
}
