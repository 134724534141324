<div class="at-top-home-nav" (click)="app.goHome()">
  <span><mat-icon>chevron_left</mat-icon></span>
  <span style="position:relative; top:-7px;">{{app.text['app']['return-link']}}</span>
</div>

<div class="at-icon">
  <div class="at-icon-flag">
    <img src="/assets/flag.png" width="100%" alt="Flag">
  </div>
  <div class="at-icon-text">{{app.text['app']['translated']}}</div>
</div>

<div *ngIf="signUpOk">
  <div class="at-signup-success">
    {{app.text['signup']['success']}}
  </div>
</div>

<div *ngIf="!signUpOk">
  <div class="at-signup-title">
    {{app.text['signup']['user-registration']}}
  </div>

  <div class="at-signup-field">
    <mat-form-field class="at-signup-field-text">
      <mat-label>{{app.text['signup']['email-address']}}</mat-label>
      <input matInput type="email" [(ngModel)]="request.email">
    </mat-form-field>
  </div>

  <div class="at-signup-field">
    <mat-form-field class="at-signup-field-text">
      <mat-label>{{app.text['signup']['screen-name']}}</mat-label>
      <input matInput type="email" [(ngModel)]="request.name">
    </mat-form-field>
  </div>

  <div class="at-signup-field">
    <mat-form-field class="at-signup-field-text">
      <mat-label>{{app.text['signup']['preferred-language']}}</mat-label>
      <mat-select [(ngModel)]="app.userSettings.lang">
        <mat-option *ngFor="let option of app.languageOptions" [value]="option.abbreviation">
          {{option.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="at-signup-field">
    <mat-checkbox class="at-signup-field-check" [(ngModel)]="request.agree">
      {{app.text['signup']['tos'][0]}}<a (click)="openTos()">{{app.text['signup']['tos'][1]}}</a>{{app.text['signup']['tos'][2]}}
    </mat-checkbox>
  </div>

  <div class="at-signup-field">
    <button class="at-signup-field-text" mat-raised-button color="primary" [disabled]="!request.agree || inProgress" (click)="doSignup()">
      {{app.text['signup']['create-account']}}
      &nbsp;&nbsp;
      <mat-icon *ngIf="inProgress" class="spin">autorenew</mat-icon>
    </button>
  </div>
</div>
