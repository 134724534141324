<div class="at-app-menubar" *ngIf="text !== undefined">
  <span class="at-float-left">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="goLogin()" *ngIf="!isLoggedIn">
        <mat-icon>person</mat-icon>
        <span>{{text['app']['login-button']}}</span>
      </button>
      <button mat-menu-item (click)="goSignup()" *ngIf="!isLoggedIn">
        <mat-icon>library_add</mat-icon>
        <span>{{text['app']['signup-button']}}</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="languages">
        <mat-icon>languages</mat-icon>
        <span>{{text['app']['languages-button']}}</span>
      </button>
      <button mat-menu-item (click)="goSettings()" *ngIf="isLoggedIn">
        <mat-icon>settings</mat-icon>
        <span>{{text['app']['settings-button']}}</span>
      </button>
      <button mat-menu-item (click)="goLogout()" *ngIf="isLoggedIn">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{text['app']['logout-button']}}</span>
      </button>
    </mat-menu>
    <mat-menu #languages="matMenu">
      <button mat-menu-item *ngFor="let language of languageOptions" (click)="setLanguage(language.abbreviation)">
        <span>{{language.display}}</span>
      </button>
    </mat-menu>
  </span>
  <span class="at-dev-title" *ngIf="apiIsDev">
    Development
  </span>
  <span class="at-float-right at-horiz-pad" *ngIf="user === undefined">
    <button mat-stroked-button (click)="router.navigateByUrl('/signup')">
      {{text['app']['signup-button']}}
    </button>
  </span>
  <span class="at-float-right at-horiz-pad" *ngIf="user === undefined">
    <button mat-stroked-button (click)="router.navigateByUrl('/login')">
      {{text['app']['login-button']}}
    </button>
  </span>
  <span class="at-float-right at-horiz-pad at-shift-down at-user-id-container" *ngIf="user !== undefined">
    {{user.fullname}}
  </span>
</div>

<div class="at-app-mainbody" *ngIf="text !== undefined">
  <router-outlet></router-outlet>
</div>
