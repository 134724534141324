import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../app.component";

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.sass']
})
export class TosComponent implements OnInit
{
  /**
   * Reference to the main application singleton
   */
  app: AppComponent;


  constructor()
  {
    this.app = AppComponent.singleton;
  }


  ngOnInit(): void
  {
  }

}
