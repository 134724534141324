import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../app.component";
import {AmericaTranslated} from "../../../../api/src/at";
import Document = AmericaTranslated.Document;
import DocumentNode = AmericaTranslated.DocumentNode;
import Comment = AmericaTranslated.Comment;


@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.sass']
})
export class ReadComponent implements OnInit
{
  /**
   * Reference to the main application component
   */
  app: AppComponent;


  /**
   * Flag to tell us if a document is selected
   */
  documentSelected: boolean = false;


  /**
   * Flag to tell us if a document is being loaded from the API
   */
  inProgress: boolean = false;


  /**
   * Current document (if any)
   */
  document: Document|undefined;


  /**
   * Cache for all loaded documents
   */
  documentCache: any = {};


  /**
   * Current position in the document index tree
   */
  documentBranch: Array<DocumentNode> = [];


  /**
   * A list of documents last browsed
   */
  documentHistory: Array<any> = [];


  /**
   * A flag to tell us if we are submitting a new comment
   */
  isAddingComment: boolean = false;


  /**
   * A flag to tell us to show comments
   */
  isShowingComments: boolean = false;


  /**
   * A flag to tell us if the comments are loading
   */
  isLoadingComments: boolean = false;


  /**
   * List of comments for the current document
   */
  comments: Array<Comment> = new Array<Comment>();


  /**
   * Comment entered by the user
   */
  userComment: string = '';


  /**
   * A flag to tell us is a vote is in flight
   */
  isVoting: boolean = false;


  /**
   * Default constructor
   */
  constructor()
  {
    this.app = AppComponent.singleton;
    this.app.addDocumentChangeEventListener(this.documentIndexChanged.bind(this));
    this.app.addLanguageChangeEventListener(this.languageChanged.bind(this));
    this.initDocumentBranch();
  }


  /**
   * Initialize the vector
   */
  ngOnInit(): void
  {
  }


  /**
   * Initialize the document branch
   */
  initDocumentBranch(): void
  {
    this.documentBranch = this.app.documentIndex;

    if (this.documentBranch.length === 0)
      setTimeout(this.initDocumentBranch.bind(this), 100);
    else
      this.loadDocumentByRoute();
  }


  /**
   * Documents changed listener
   *
   * @param documents New list of documents
   */
  documentIndexChanged(documents: Array<DocumentNode>): void
  {
    this.documentBranch = documents;
  }


  /**
   * Language changed listener
   *
   * @param lang Newly selected language
   */
  languageChanged(lang: string): void
  {
    if (this.document !== undefined)
      this.loadDocument(this.document.id);
  }


  /**
   * Load a document by the route
   */
  loadDocumentByRoute(): void
  {
    let route = this.app.router.url;
    if (route.startsWith('/doc'))
      route = route.endsWith('/') ? route.substring(5) : route.substring(4);

    if (route.startsWith('/'))
      route = route.substring(1);

    if (route !== '')
      this.loadDocument(route);
  }


  /**
   * Load a document to be displayed
   *
   * @param docId Document ID
   */
  loadDocument(docId: string): void
  {
    this.documentSelected = true;

    /* set the route for this document ID */
    this.app.router.navigateByUrl('/doc/' + docId);

    /* check the cache, load if not found */
    const lang = this.app.userSettings.lang;
    if (this.documentCache[lang] !== undefined && this.documentCache[lang][docId] !== undefined)
      this.document = this.documentCache[lang][docId];
    else
    {
      this.inProgress = true;
      this.app.api.getDocument(this.receiveDocument.bind(this), lang, docId);
    }
  }


  /**
   * Set the document history from the given route
   *
   * @param route The document ID
   * @param searchNode Use this as the root node to search instead of the index root
   */
  setDocumentHistoryFromRoute(route: string, searchNode?: Array<DocumentNode>): boolean
  {
    /* use the index root to search if none was provided */
    if (searchNode === undefined)
      searchNode = this.app.documentIndex;

    /* search for the document leaf node in this branch */
    for (let docNode of searchNode)
    {
      if (docNode.type === 'folder')
      {
        if (this.setDocumentHistoryFromRoute(route, docNode.documents))
        {
          this.documentHistory.splice(0, 0, docNode);
          return true;
        }
      }
      else if (docNode.id === route)
      {
        return true;
      }
    }

    /* not found in this branch */
    return false;
  }


  /**
   * Clear the selected document
   */
  clearDocument(): void
  {
    this.documentSelected = false;
    this.document = undefined;
    this.isShowingComments = false;
    this.comments = new Array<Comment>();
  }


  /**
   * Receive the document from the API
   *
   * @param res
   * @param statusText
   * @param status
   */
  receiveDocument(res: any, statusText: string, status: string): void
  {
    this.inProgress = false;

    if (res.ok)
    {
      this.document = res.data.doc;

      if (this.document === undefined)
        this.documentSelected = false;
      else
      {
        const lang = this.app.userSettings.lang;
        this.documentCache[lang] = this.documentCache[lang] || {};
        this.documentCache[lang][this.document.id] = this.document;
        this.documentHistory = [];
        this.setDocumentHistoryFromRoute(this.document.id);
      }
    }
    else
    {
      this.documentSelected = false;
      this.app.showErrors(res.errors);
    }
  }


  /**
   * Set a new position in the document tree
   *
   * @param node New current location in the document tree
   */
  expandNode(node: DocumentNode): void
  {
    this.documentHistory[this.documentHistory.length] = node;

    if (node.type === 'folder')
    {
      if (node.documents !== undefined)
        this.documentBranch = node.documents;
    }
    else if (node.type === 'document')
    {
      if (node.id !== undefined)
        this.loadDocument(node.id);
    }
  }


  /**
   * Rewind history to a given step
   *
   * @param step Index in the history array to jump to
   */
  public rewindHistory(step?: number): void
  {
    if (step === undefined)
    {
      const position = Math.max(-1, this.documentHistory.length - 2);
      this.rewindHistory(position);
      return;
    }

    this.clearDocument();

    if (step === -1)
    {
      this.documentHistory = [];
      this.documentBranch = this.app.documentIndex;
    }

    if (this.documentHistory[step].type === 'folder')
    {
      this.documentHistory = this.documentHistory.slice(0, step+1);
      this.documentBranch = this.documentHistory[step].documents;
    }
  }


  /**
   * Post the comment when enter is pressed
   *
   * @param event Key event
   */
  public addCommentOnEnter(event: any): void
  {
    if (event.code === 'Enter' && !event.shiftKey)
      this.addComment();
  }


  /**
   * Add a new comment to the current document
   */
  public addComment(): void
  {
    if (this.document !== undefined)
    {
      this.isAddingComment = true;
      this.app.api.addComment(this.handleAddCommentResponse.bind(this), this.document.id, this.app.userSettings.lang, this.userComment);
    }
  }


  /**
   * Handle a response to an "add comment" request
   *
   * @param res
   * @param statusText
   * @param status
   * @private
   */
  private handleAddCommentResponse(res: any, statusText: string, status: any): void
  {
    this.isAddingComment = false;
    if (res.ok)
    {
      this.comments[this.comments.length] = res.data.comment;
      this.userComment = '';
      this.showComments();
    }
    else
      this.app.showErrors(res.errors);
  }


  /**
   * Show the comments for the current document
   */
  public showComments(): void
  {
    if (this.document !== undefined)
    {
      this.isLoadingComments = true;
      this.isShowingComments = true;
      this.app.api.getComments(this.receiveComments.bind(this), this.document.id, this.app.userSettings.lang);
    }
  }


  /**
   * Get a date/time string from a timestamp
   * @param timestamp Number of seconds since 1970-Jan-01 00:00:00.000 UTC
   */
  public getCommentDateTime(timestamp: number): string
  {
    const date = new Date(timestamp * 1000);
    const now = new Date();
    const delta = (now.getTime() - date.getTime()) / 1000;

    /* use relative-style dates for more recent comments */
    // if (delta < 86400 * 7)
    // {
    //   if (delta < 60)
    //     return Math.round(delta) + ' seconds ago';
    //   if (delta < 3600)
    //     return Math.round(delta / 60) + ' minutes ago';
    //   if (delta < 86400)
    //     return Math.round(delta / 3600) + ' hours ago';
    //   return Math.round(delta / 86400) + ' days ago';
    // }

    /* use absolute-style dates for older comments */
    const y = date.getFullYear();
    const M = date.getMonth();
    const d = date.getDate();
    const h = date.getHours();
    const m = date.getMinutes();
    const s = date.getSeconds();
    const S = date.getMilliseconds();

    const yyyy = ('0000' + y).substr(-4, 4);
    const mon = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][M];
    const dd = ('00' + d).substr(-2, 2);
    const hh = ('00' + h).substr(-2, 2);
    const mm = ('00' + m).substr(-2, 2);
    const ss = ('00' + s).substr(-2, 2);
    const SSS = ('000' + S).substr(-3, 3);
    return yyyy + '-' + mon + '-' + dd + ' ' + hh + ':' + mm;
  }


  /**
   * Handle an API response with comments for a document
   *
   * @param res
   * @param statusText
   * @param status
   * @private
   */
  private receiveComments(res: any, statusText: string, status: any): void
  {
    if (res.ok)
    {
      this.isLoadingComments = false;
      this.comments = res.data.comments;
    }
    else
    {
      this.app.showErrors(res.errors);
      this.isLoadingComments = false;
      this.isShowingComments = false;
    }
  }


  /**
   * Up vote a comment
   *
   * @param comment Comment object to vote
   */
  public voteUp(comment: Comment): void
  {
    this.vote(comment, 1);
  }


  /**
   * Up vote a comment
   *
   * @param comment Comment object to vote
   */
  public voteDown(comment: Comment): void
  {
    this.vote(comment, -1);
  }


  /**
   * Up vote a comment
   *
   * @param comment Comment object to vote
   * @param direction Positive number to up vote, negative number to down vote
   */
  private vote(comment: Comment, direction: number): void
  {
    /* check if there is a vote already in flight */
    if (this.isVoting)
      return;

    /* a vote is in flight now */
    this.isVoting = true;

    this.app.api.voteOnComment(this.receiveVote.bind(this), comment.cid, direction);
  }


  /**
   * Handle a vote response
   *
   * @param res
   * @param statusText
   * @param status
   * @private
   */
  private receiveVote(res: any, statusText: string, status: any): void
  {
    /* the vote is not in flight */
    this.isVoting = false;

    /* check the response */
    if (res.ok)
      for (let comment of this.comments)
        if (comment.cid === res.data.comment.cid)
        {
          comment.up = res.data.comment.up;
          comment.dn = res.data.comment.dn;
          return;
        }

    /* show error messages to the user */
    if (!res.ok)
      this.app.showErrors(res.errors);
  }
}
