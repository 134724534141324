import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {LoginComponent} from './login/login.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {SignupComponent} from './signup/signup.component';
import {ReadComponent} from './read/read.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDialogModule} from "@angular/material/dialog";
import {TosComponent} from './tos/tos.component';
import {ActivateComponent} from './activate/activate.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {LogoutComponent} from './logout/logout.component';
import {MatMenuModule} from "@angular/material/menu";
import {SettingsComponent} from './settings/settings.component';
import {MatSelectModule} from "@angular/material/select";
import {HttpClientModule} from "@angular/common/http";
import { UnderConstructionComponent } from './under-construction/under-construction.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SignupComponent,
        ReadComponent,
        TosComponent,
        ActivateComponent,
        PasswordResetComponent,
        LogoutComponent,
        SettingsComponent,
        UnderConstructionComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatDialogModule,
        MatMenuModule,
        MatSelectModule,
        HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule
{
}
