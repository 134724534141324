<div class="at-icon">
	<div class="at-icon-flag">
		<img src="/assets/flag.png" width="100%" alt="Flag">
	</div>
	<div class="at-icon-text">app.text['app']['translated']</div>
</div>

<div class="at-logout-container">
	<span>app.text['logout']['logging-out'] &nbsp;&nbsp;</span>
	<span>
		<mat-icon class="spin at-shift-down">autorenew</mat-icon>
	</span>
</div>
