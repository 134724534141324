import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../app.component";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.sass']
})
export class SettingsComponent implements OnInit
{
  /**
   * Reference to the main app
   */
  app: AppComponent;


  /**
   * Default constructor
   */
  constructor()
  {
    /* get a reference to the app singleton */
    this.app = AppComponent.singleton;
  }


  /**
   * After load
   */
  ngOnInit(): void
  {
  }


  /**
   * Save user settings.py
   */
  doSaveSettings(): void
  {
    this.app.api.saveSettings(this.receivedSaveSettings.bind(this), this.app.userSettings);
  }


  /**
   * Handle a reponse message from saving the user settings request
   * @param res
   * @param statusText
   * @param status
   */
  receivedSaveSettings(res: any, statusText: string, status: string): void
  {
    if (res.ok)
    {
      this.app.showSuccess(this.app.text['settings']['save-success']);
      this.app.setLanguage();
    }
    else
    {
      this.app.showErrors(res.errors);
    }
  }
}
