import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../app.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit
{
  /**
   * Reference to the main application
   */
  app: AppComponent;


  /**
   * Flag to indicate the user forgot their password
   */
  forgotPassword: boolean = false;


  /**
   * User's email input field
   */
  email: string = '';


  /**
   * User's password input field
   */
  password: string = '';


  /**
   * Flag to tell us if we are waiting on a response from the API
   */
  inProgress: boolean = false;


  /**
   * Flag to tell us if the reset password response is ok
   */
  resetOk: boolean = false;


  /**
   * Default constructor
   */
  constructor()
  {
    this.app = AppComponent.singleton;
  }


  /**
   * Called after UI components up
   */
  ngOnInit(): void
  {
  }


  /**
   * Call do login if the key pressed is enter
   *
   * @param event Key press event
   */
  doLoginOnEnter(event: any)
  {
    if (event.keyCode === 13)
      this.doLogin();
  }


  /**
   * Send a login message to the
   */
  doLogin(): void
  {
    this.inProgress = true;
    this.app.api.login(this.email, this.password, this.loginReceived.bind(this));
  }


  /**
   * Handle a login response
   *
   * @param res
   * @param statusText
   * @param status
   */
  loginReceived(res: any, statusText: string, status: string): void
  {
    this.inProgress = false;
    if (res.ok)
    {
      this.app.user = res.data.user;
      this.app.userSettings = res.data.settings;
      this.app.goHome();
      this.app.isLoggedIn = true;
      this.app.updateMenu();
    }
    else
      this.app.showErrors(res.errors);
  }


  /**
   * Send a message to the API to add a password reset link
   */
  doResetPassword(): void
  {
    this.inProgress = true;
    this.app.api.addPasswordResetToken(this.resetPasswordReceived.bind(this), this.email);
  }


  /**
   * Handle a reset password response
   *
   * @param res
   * @param statusText
   * @param status
   */
  resetPasswordReceived(res: any, statusText: string, status: string): void
  {
    this.inProgress = false;
    this.resetOk = res.ok;
  }
}
