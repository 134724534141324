import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../app.component";

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.sass']
})
export class UnderConstructionComponent implements OnInit
{

  constructor()
  {
  }


  ngOnInit(): void
  {
  }


  public closeUnderConstruction()
  {
    AppComponent.singleton.closeUnderConstruction();
  }
}
