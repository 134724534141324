<div class="at-top-home-nav" (click)="app.goHome()">
  <span><mat-icon>chevron_left</mat-icon></span>
  <span style="position:relative; top:-7px;">{{app.text['app']['return-link']}}</span>
</div>

<div class="at-icon">
  <div class="at-icon-flag">
    <img src="/assets/flag.png" width="100%" alt="Flag">
  </div>
  <div class="at-icon-text">{{app.text['app']['translated']}}</div>
</div>

<div *ngIf="!pwresetOk && !resetTokenIsExpired">
  <div class="at-pwreset-title">
    {{app.text['reset-password']['reset-password']}}
  </div>

  <div class="at-pwreset-pw-complexity-hint" *ngIf="showComplexityHint">
    {{app.text['activate']['password-hint-0']}}
    <span style="font-weight:{{this.isLongEnough?'normal':'bold'}}">{{app.text['activate']['password-hint-1']}}</span>{{app.text['activate']['password-hint-2']}}
    <span style="font-weight:{{this.hasUpper?'normal':'bold'}}">{{app.text['activate']['password-hint-3']}}</span>
    {{app.text['activate']['password-hint-4']}}
    <span style="font-weight:{{this.hasLower?'normal':'bold'}}">{{app.text['activate']['password-hint-5']}}</span> {{app.text['activate']['password-hint-6']}}
    <span style="font-weight:{{this.hasNumeric?'normal':'bold'}}">{{app.text['activate']['password-hint-7']}}</span>{{app.text['activate']['password-hint-8']}}
  </div>

  <div class="at-pwreset-field">
    <mat-form-field class="at-pwreset-field-text">
      <mat-label>{{app.text['activate']['new-password-label']}}</mat-label>
      <input matInput type="password" [(ngModel)]="password1" (keypress)="validatePassword()">
    </mat-form-field>
  </div>

  <div class="at-pwreset-field">
    <mat-form-field class="at-pwreset-field-text">
      <mat-label>{{app.text['activate']['new-password-again-label']}} {{passwordMatches}}</mat-label>
      <input matInput type="password" [(ngModel)]="password2" (keypress)="validatePassword()">
    </mat-form-field>
  </div>

  <div class="at-pwreset-field">
    <button class="at-pwreset-field-text" mat-raised-button color="primary" [disabled]="inProgress || !passwordOk" (click)="doResetPassword()">
      {{app.text['reset-password']['reset-password']}}
      &nbsp;&nbsp;
      <mat-icon *ngIf="inProgress" class="spin">autorenew</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="resetTokenIsExpired">
  <div class="at-pwreset-success">
    {{app.text['reset-password']['token-exp-0']}}
    <a (click)="app.goLogin()">{{app.text['reset-password']['token-exp-1']}}</a> {{app.text['reset-password']['token-exp-2']}}
  </div>
</div>
