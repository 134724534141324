import {Component, OnInit} from '@angular/core';
import {ParamMap} from "@angular/router";
import {AppComponent} from "../app.component";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.sass']
})
export class PasswordResetComponent implements OnInit
{
  /**
   * Reference to the main app component
   */
  app: AppComponent;


  /**
   * Reset token
   */
  resetToken: string = '';


  /**
   * User's email
   */
  email: string = '';


  /**
   * New password
   */
  password1: string = '';


  /**
   * New password confirmation
   */
  password2: string = '';


  /**
   * Flag to indicate we are waiting for an API response
   */
  inProgress: boolean = false;


  /**
   * Flag to indicate if password was reset successfully
   */
  pwresetOk: boolean = false;


  /**
   * Flag to indicate if password complexity hint should be visible
   */
  showComplexityHint: boolean = true;


  /**
   * Text to stick into the HTML if passwords do not match
   */
  passwordMatches: string = '';


  /**
   * Is the password long enough?
   */
  isLongEnough: boolean = false;


  /**
   * Does the password have upper case?
   */
  hasUpper: boolean = false;


  /**
   * Does the password have lower case?
   */
  hasLower: boolean = false;


  /**
   * Does the password have a number?
   */
  hasNumeric: boolean = false;


  /**
   * Tell us if the new password is complex enough
   */
  passwordOk: boolean = false;


  /**
   * Tells us is if the reset token is expired
   */
  resetTokenIsExpired: boolean = false;


  /**
   * Default constructor
   */
  constructor()
  {
    this.app = AppComponent.singleton;
  }


  /**
   * Read query string parameters
   */
  ngOnInit(): void
  {
    const paramMap: ParamMap = this.app.router.parseUrl(this.app.router.url).queryParamMap;
    this.email = paramMap.get('email') || '';
    const expAndToken = paramMap.get('reset_token') || '';
    const exp = Number.parseInt(expAndToken.split(';')[0]);
    this.resetTokenIsExpired = exp <= (new Date().getTime() / 1000);
    this.resetToken = expAndToken.split(';')[1];
  }


  /**
   * Send a message to the API to reset the user's password
   */
  doResetPassword(): void
  {
    this.inProgress = true;
    this.app.api.resetPassword(
      this.resetPasswordReceived.bind(this),
      this.resetToken,
      this.email,
      this.password1,
      this.password2
    );
  }


  /**
   * Handle a response for a password reset
   *
   * @param res
   * @param statusText
   * @param status
   */
  resetPasswordReceived(res: any, statusText: string, status: string): void
  {
    this.inProgress = false;
    if (res.ok)
      this.app.goHome();
    else
      this.app.showErrors(res.errors);
  }


  /**
   * Validate the password
   */
  validatePassword(delay: number = 1): void
  {
    if (delay === 1)
    {
      setTimeout(this.validatePassword.bind(this), 150, 0);
      return;
    }

    this.isLongEnough = this.password1.length >= 8

    this.hasUpper = false;
    for (let i = 0; i < this.password1.length; i++)
      if ('A' <= this.password1[i] && this.password1[i] <= 'Z')
      {
        this.hasUpper = true;
        break;
      }

    this.hasLower = false;
    for (let i = 0; i < this.password1.length; i++)
      if ('a' <= this.password1[i] && this.password1[i] <= 'z')
      {
        this.hasLower = true;
        break;
      }

    this.hasNumeric = false;
    for (let i = 0; i < this.password1.length; i++)
      if ('0' <= this.password1[i] && this.password1[i] <= '9')
      {
        this.hasNumeric = true;
        break;
      }

    if (this.password1 === this.password2)
      this.passwordMatches = '';
    else
      this.passwordMatches = '(' + this.app.text['activate']['passwords-no-match'] + ')';

    this.showComplexityHint = !(this.hasNumeric && this.hasLower && this.hasUpper && this.isLongEnough);

    this.passwordOk = this.passwordMatches === '' && !this.showComplexityHint;
  }
}
